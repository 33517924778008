<template>
<!-- 企业中心 -->
  <div class="BJS">
    <Header></Header>

    <div class="Bottom">
    <NavEnterprise></NavEnterprise>
      
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header"
import NavEnterprise from "@/components/NavEnterprise"
import Footer from "@/components/Footer"
export default {
    components:{
        Header,Footer,NavEnterprise
    },
    methods: {
   
    }
}
</script>

<style scoped>
.BJS {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}
.Bottom{
  width:1200px;
  margin:20px auto;
  background:#f5f5f5;
  min-height:700px;
}
</style>