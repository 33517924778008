<template>
  <div style="display:flex;">
    <el-col :span="4">
      <el-menu :default-active="this.$route.path" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
        router>
        <el-menu-item v-for="(item,index) in navList" :index="item.path" :key="index">
          <i :class="item.icon"></i>
          <span slot="title">{{item.navItem}}</span>
        </el-menu-item>
      </el-menu>
    </el-col>

    <router-view></router-view>

  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        {
          path: "/EnterpriseCenter/Homes",
          navItem: "企业主页",
          icon: "el-icon-s-home",
        },
        {
          path: "/EnterpriseCenter/EnterpriseHome",
          navItem: "投诉管理",
          icon: "el-icon-edit",
        },
        {
          path: "/EnterpriseCenter/CommentManagement",
          navItem: "评论管理",
          icon: "el-icon-document",
        },
        {
          path: "/EnterpriseCenter/NoticeNotice",
          navItem: "通知公告",
          icon: "el-icon-star-off",
        },
        {
          path: "/EnterpriseCenter/AccountManagement",
          navItem: "账号管理",
          icon: "el-icon-chat-dot-square",
        },
        {
          path: "/EnterpriseCenter/Help",
          navItem: "帮助反馈",
          icon: "el-icon-bell",
        },
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      //console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      //console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>
>>> .el-menu-vertical-demo {
  text-align: center;
}
>>> .el-col{
  float:none !important;
}
>>> .el-menu-item:focus, .el-menu-item:hover{
  background:#1F72D9;
  color:#fff;
}
</style>